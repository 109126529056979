<template>
    <div class="">
        <div class="fl-x-cc">
            <h4 class="text-center text-7 mb-3">Dictionary</h4>
        </div>
        <div>
            <div class="col-lg-12 p-0">
                <validated-input placeholder="search" type="text" :border-radius="1" v-model="search"></validated-input>
            </div>
            <template v-if="data!=''">
               <div class="col-lg-12 b-1 bs-1 mt-3" v-for="(item,index) in data" :key="index">
                    <ExpandableCard :title="item.title"
                                    :description="item.description"/>
                </div>
            </template>

        <div class="fl-x fl-j-c" v-else>
            <div class="fl-x" v-if="loading">
              <loading-animation></loading-animation>
             <p>Loading...</p>
          </div>
             <found-item v-else></found-item>

       </div>

        </div>
    </div>
</template>
<script>
import ExpandableCard from '@components/cards/ExpandableCard';
import axios from 'secure-axios';
import urls from '@/data/urls';
import FoundItem from '@/views/user-home/disease/FoundItem';

export default {
    name: 'Dictionary',
    components: { FoundItem, ExpandableCard },
    watch: {
        search (newVal, oldVal) {
            console.log(newVal, oldVal);
            this.searchItem();
        }
    },
    data () {
        return {
            loading: false,
            startSearch: false,
            data: [],
            listURL: urls.userHome.userDictionary.list,
            search: ''

        };
    },
    mounted () {
        this.fetchItem();
    },
    methods: {
        async searchItem () {
            this.startSearch = true;
            console.log('Searching...');
            if (this.search === undefined) {
                this.search = '';
            }
            try {
                this.loading = true;
                const response = await axios.get(this.listURL + '?search=' + this.search);
                const result = response.data.data;
                this.data = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchItem () {
            console.log('Fetching...');
            try {
                this.loading = true;
                const response = await axios.get(this.listURL);
                const result = response.data.data;
                this.data = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }

};
</script>

<style scoped>

</style>
