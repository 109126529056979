<template>
    <div class="">
        <div class=" ">
            <div class="card p-1">
                <div class="card-header" @click="toggleExpand">
                    <p class="text-seventh font-weight-400 mb-0 p-2 text-center">{{ title }}</p>
                </div>
                <div class="card-body" v-if="expanded">
                    <p class="text-center pt-2 font-weight-300 text-4">
                        <span class="pt-2 font-weight-500 text-2">Description: </span>{{ description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExpandableCard',
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            expanded: false
        };
    },
    methods: {
        toggleExpand () {
            this.expanded = !this.expanded;
        }
    }
};
</script>

<style scoped>

</style>
